const settings	=	{
	color:	{
		bg: {
			light: 'RGBa(252, 208, 96,1)',
			dark: '#1e1e1e',
		},
		font: {
			dark: '#414042',
			light: '#8e8e8e',
		},
	},
	host:	{
		name: 'googleauthenticator.dev',
		url: 'https://www.googleautenticator.dev',
	},
};

settings.recaptcha	=	{
//	site_key	:	'6LexU_cZAAAAAFRC6Am7sI6gZ3BWUpkDg8w5F-fB',	//	Public:		Client-side Integration
};

settings.endpoints	=	{
	api_base_url	:	'https://api.googleauthenticator.dev',
};
settings.endpoints.forms	=	{
	verify_recaptcha	:	`${settings.endpoints.api_base_url}/recaptcha`,
	submit_contact		:	`${settings.endpoints.api_base_url}/contact`,
	submit_careers		:	`${settings.endpoints.api_base_url}/careers`,
};
settings.example_values	=	{
	qr_code_scan			:	'otpauth-migration://offline?data=CiMKCskCjMDVZlPV3OQSD015IFRlc3QgQWNjb3VudCABKAEwAhAB',
	data_parameter			:	'CiMKCskCjMDVZlPV3OQSD015IFRlc3QgQWNjb3VudCABKAEwAhAB',
	shared_secret_as_base64	:	'yQKMwNVmU9Xc5A==',
	shared_secret_as_base32	:	'ZEBIZQGVMZJ5LXHE',
	totp					:	'686130',
};


export default settings;
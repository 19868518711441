// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-step-1-qr-code-scanner-index-js": () => import("./../../../src/pages/index/Step1_QrCodeScanner/index.js" /* webpackChunkName: "component---src-pages-index-step-1-qr-code-scanner-index-js" */),
  "component---src-pages-index-step-2-decode-data-into-protocol-buffer-index-js": () => import("./../../../src/pages/index/Step2_DecodeDataIntoProtocolBuffer/index.js" /* webpackChunkName: "component---src-pages-index-step-2-decode-data-into-protocol-buffer-index-js" */),
  "component---src-pages-index-step-2-decode-data-into-protocol-buffer-protocol-buffer-decode-index-js": () => import("./../../../src/pages/index/Step2_DecodeDataIntoProtocolBuffer/ProtocolBufferDecode/index.js" /* webpackChunkName: "component---src-pages-index-step-2-decode-data-into-protocol-buffer-protocol-buffer-decode-index-js" */),
  "component---src-pages-index-step-3-encode-buffer-as-base-32-base-32-encode-index-js": () => import("./../../../src/pages/index/Step3_EncodeBufferAsBase32/Base32Encode/index.js" /* webpackChunkName: "component---src-pages-index-step-3-encode-buffer-as-base-32-base-32-encode-index-js" */),
  "component---src-pages-index-step-3-encode-buffer-as-base-32-index-js": () => import("./../../../src/pages/index/Step3_EncodeBufferAsBase32/index.js" /* webpackChunkName: "component---src-pages-index-step-3-encode-buffer-as-base-32-index-js" */),
  "component---src-pages-index-step-4-generate-totp-from-secret-index-js": () => import("./../../../src/pages/index/Step4_GenerateTotpFromSecret/index.js" /* webpackChunkName: "component---src-pages-index-step-4-generate-totp-from-secret-index-js" */)
}


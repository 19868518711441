import './src/styles/index.scss';
import settings from './src/settings';

//	----------------
//	Google Recaptcha
//	----------------
import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
export const wrapRootElement = function({ element }){
	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={settings.recaptcha.site_key}
			scriptProps={{
				async	:	true, // optional, default to false,
				defer	:	true, // optional, default to false
				appendTo:	'body', // optional, default to "head", can be "head" or "body",
//				nonce	:	undefined // optional, default undefined
			}}
			>
			{element}
		</GoogleReCaptchaProvider>
	);
};